.loading {
  width: 100%;
  height: 100%;
  & div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
